<template>
  <div class="loginWrap" :class="returnBgClass()">
    <img src="../../../public/new/Bubbles.png" alt="" class="topBc" />
    <van-dialog
      :before-close="reLoginBefore"
      v-model="reLoginModal"
      title="登入"
      show-cancel-button
    >
      <div style="padding: 15px">
        <p style="font-size: 14px">
          已在其他设备登入，请问是否踢除并重新登入？
        </p>
      </div>
    </van-dialog>
    <div class="loginFromWrap">
      <h2 class="loginTitle" style="z-index: 99">
        lili商城
        <h3 class="loginTitleIngo">
          很高兴见到你 <img src="/new/heart.png" alt="" />
        </h3>
      </h2>
      <div class="inputRound">
        <label for="">账号</label>
        <input v-model="username" :type="'text'" autocomplete="off" />
      </div>
      <div class="inputRound">
        <label for="">密码</label>
        <input
          v-model="password"
          :type="isPassword ? 'password' : 'text'"
          autocomplete="off"
        />
        <div class="rightIcon" @click="isPassword = !isPassword">
          <img
            src="../../assets/images/login_eye.png"
            alt=""
            v-if="!isPassword"
          />
          <img src="../../assets/images/login_eye(closed).png" v-else alt="" />
        </div>
      </div>
      <div class="inputRound validateWrap">
        <label for="">验证码</label>
        <input v-model="captchaCode" :type="'text'" autocomplete="off" />
        <img
          :src="captcha.img"
          alt=""
          class="validateImg"
          @click="getCaptcha"
        />
        <div class="rightIcon" @click="getCaptcha">
          <img src="../../assets/images/login_reset.png" alt="" />
        </div>
      </div>
      <div class="loginBtnWrap" @click="login">
        <p>登入</p>
      </div>
    </div>
    <div class="btnI">
      <img
        src="/new/bubble 04.png"
        alt=""
        style="width: 213px; right: 0; bottom: 0px"
      />
    </div>
    <van-dialog
      :before-close="FALoginBefore"
      v-model="FALoginModal"
      title="谷歌验证码"
      show-cancel-button
    >
      <van-form @submit="FALogin" ref="FALoginForm">
        <van-field
          v-model="oneCode"
          name="验证码"
          label="验证码"
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        />
      </van-form>
    </van-dialog>
    <van-dialog
      class="FAModal"
      :before-close="bindBefore"
      v-model="FABind"
      title="绑定谷歌验证码"
      show-cancel-button
    >
      <div style="width: 100%; display: flex; justify-content: center">
        <vue-qrcode
          :value="encodeURI(`otpauth://totp/${username}?secret=${secret}`)"
        />
      </div>
      <van-form @submit="bindFA" ref="oneCodeForm">
        <van-field label="密钥" :value="secret" readonly />
        <van-button
          v-clipboard:copy="secret"
          v-clipboard:success="onCopy"
          type="primary"
          block
          >复制</van-button
        >
        <van-field
          v-model="oneCode"
          name="验证码"
          label="验证码"
          placeholder="验证码"
          :rules="[{ required: true, message: '请填写验证码' }]"
        />
      </van-form>
    </van-dialog>
  </div>
</template>
<script>
import VueQrcode from "vue-qrcode";
export default {
  components: {
    VueQrcode,
  },
  data() {
    return {
      token: "",
      isPassword: true,
      username: "",
      password: "",
      FABind: false,
      secret: "",
      oneCode: "",
      captcha: {},
      captchaTime: 60000,
      captchaTimeOut: "",
      captchaCode: "",
      FALoginModal: false,
      FAData: "",
      currentLoginData: "",
      reLoginModal: false,
      reloginData: {},
      title: "",
      isTHH:
        document.domain == process.env.VUE_APP_TYH ||
        document.domain == "43.138.153.196" ||
        document.domain == "ht.hdsaj.com",
    };
  },
  created() {
    this.getCaptcha();

    const domainToTitleMap = {
      [process.env.VUE_APP_TAIYUN]: "Lili商城",
      ["store.rxlz861.com"]: "Lili商城",
      "122.152.225.41": "Lili商城",
      [process.env.VUE_APP_CROWN]: "尚华",
      [process.env.VUE_APP_VPHELLO]: "老爷商城",
      "go.tiany8989.com": "大唐支付",
      [process.env.VUE_APP_TYH]: "海淘天下",
      "ht.hdsaj.com": "海淘天下",
      "43.138.153.196": "海淘天下",
      "store.rzxl923.com": "海淘天下",
      [process.env.VUE_APP_ZHAOFU]: "兆富商城",
      [process.env.VUE_APP_LEEJIA]: "李家大院商城",
      "store.niks882.com": "李家大院商城",
      "store.kxzi342.com": "鉅和支付",
      "store.rxjh382.com": "鉅和支付",
      "43.139.102.188": "鉅和支付",
      "store.olzde8213.com": "顺丰",
      "42.194.148.159": "顺丰",
    };

    this.title = domainToTitleMap[document.domain] || "Lili商城";
  },
  methods: {
    retrunTitleColor() {
      if (document.domain == "store.olzde8213.com") {
        return "color:black";
      }
    },
    returnBgClass() {
      if (
        document.domain == process.env.VUE_APP_TYH ||
        document.domain == "ht.hdsaj.com"
      ) {
        return "tyhBG";
      }
      if (document.domain == process.env.VUE_APP_CROWN) {
        return "shBG";
      }
    },
    getCaptcha() {
      this.$http.get(`/mobile/captcha`).then((res) => {
        if (res.data.code == 0) {
          this.captcha = res.data.data;
          clearTimeout(this.captchaTimeOut);
          if (this.$route.name != "userLogin") return;
          this.captchaTimeOut = setTimeout(() => {
            this.getCaptcha();
          }, this.captchaTime);
        }
      });
    },
    onCopy() {
      this.$toast("复制成功");
    },
    reLoginBefore(method, done) {
      if (method == "confirm") {
        this.$http.post(`/mobile/validate2FA`, this.reloginData).then((res) => {
          if (res.data.code == 0) {
            this.reLoginModal = false;
            this.$toast.success("登入成功");
            this.$router.push("/");
            this.$http.defaults.headers.common.Authorization =
              "Bearer " + res.data.data.access_token;
            this.$cookie.set("token", res.data.data.access_token, {
              expires: 365,
            });
          }
        });
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
    FALoginBefore(method, done) {
      if (method == "confirm") {
        this.$refs.FALoginForm.submit();
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
    bindBefore(method, done) {
      if (method == "confirm") {
        this.$refs.oneCodeForm.submit();
        done(false);
      } else {
        this.FABind = false;
        done();
        return;
      }
    },
    bindFA() {
      this.$http
        .post(`/mobile/login`, {
          username: this.username,
          password: this.password,
          oneCode: this.oneCode,
          domain: location.host,
          relogin: true,
        })
        .then((res) => {
          if (res.data.code == 0) {
            this.$toast.success("登入成功");
            this.$router.push("/");
            this.$http.defaults.headers.common.Authorization =
              "Bearer " + res.data.data.access_token;
            this.$cookie.set("token", res.data.data.access_token, {
              expires: 365,
            });
          }
          if (res.data.code == "10011") {
            this.reLoginModal = true;
            this.currentLoginData = {
              username: this.username,
              password: this.password,
              oneCode: this.oneCode,
              key: this.captcha.key,
              captcha: this.captchaCode,
              relogin: true,
            };
          }
        });
    },
    login() {
      this.secret = "";
      if (!this.username) {
        this.$toast.fail("账号");
        return;
      }
      if (!this.password) {
        this.$toast.fail("密码");
        return;
      }
      if (!this.captchaCode) {
        this.$toast.fail("验证码");
        return;
      }
      let sendData = {
        username: this.username,
        password: this.password,
        key: this.captcha.key,
        captcha: this.captchaCode,
        domain: location.host,
        relogin: true,
      };

      this.$http.post(`/mobile/login`, sendData).then((res) => {
        if (res.data.code == 10002) {
          let loginFalse = this.$cookie.get("loginFalse");
          if (loginFalse) {
            if (loginFalse * 1 >= 3) {
              this.$dialog.confirm({
                className: "loginFalse",
                title: "连续登入失败",
                message: `
                    <p style="width:100%;text-align:center;margin:0;padding:0;padding-top:15px;">如忘记密码请洽上级或客服重置密码！</p>
                    <p style="width:100%;text-align:center;margin:0;padding:0;color:red;margin-top:15px">※ 注意！连续失败次数过多将封锁IP ※</p>`,
                showCancelButton: false,
              });
            }
            this.$cookie.set("loginFalse", loginFalse * 1 + 1);
          } else {
            this.$cookie.set("loginFalse", 1);
          }
        }
        if (res.data.code == "50000") {
          this.FALoginModal = true;
          this.$cookie.set("loginFalse", 0);
          // this.FAData = res.data.data.validate2FA;
          this.currentLoginData = sendData;
          return;
        }
        if (res.data.code != 0) {
          this.getCaptcha();
          return;
        }
        if (res.data.data.role_name) {
          this.$cookie.set("loginFalse", 0);
          this.$toast.success("登入成功");
          this.$router.push("/");
          this.$http.defaults.headers.common.Authorization =
            "Bearer " + res.data.data.access_token;
          this.$cookie.set("token", res.data.data.access_token, {
            expires: 365,
          });
        }
        delete sendData.relogin;
        if (res.data.data.bind2FA) {
          this.$cookie.set("loginFalse", 0);
          this.secret = res.data.data.bind2FA.secret;
          this.FABind = true;
        }
        if (res.data.data.validate2FA) {
          this.$cookie.set("loginFalse", 0);
          this.FALoginModal = true;
          this.FAData = res.data.data.validate2FA;
          this.currentLoginData = sendData;
        }
      });
    },
    FALogin() {
      let sendData = JSON.parse(JSON.stringify(this.currentLoginData));
      sendData.oneCode = this.oneCode;
      sendData.domain = location.host;

      delete sendData.key;
      delete sendData.captcha;
      this.$http.post(`/mobile/login`, sendData).then((res) => {
        if (res.data.code == "50000") {
          this.FALoginModal = true;
          // this.FAData = res.data.data.validate2FA;
          this.currentLoginData = sendData;
          return;
        }
        if (res.data.code != 0) {
          this.getCaptcha();
          return;
        }
        if (res.data.data.role_name) {
          this.$toast.success("登入成功");
          this.$router.push("/");
          this.$http.defaults.headers.common.Authorization =
            "Bearer " + res.data.data.access_token;
          this.$cookie.set("token", res.data.data.access_token, {
            expires: 365,
          });
        }
        delete sendData.relogin;
        if (res.data.data.bind2FA) {
          this.secret = res.data.data.bind2FA.secret;
          this.FABind = true;
        }
        if (res.data.data.validate2FA) {
          this.FALoginModal = true;
          this.FAData = res.data.data.validate2FA;
          this.currentLoginData = sendData;
        }
      });
      // sendData.validate2FA = this.FAData;
      // this.$http.post(`/mobile/validate2FA`, sendData).then((res) => {
      //   if (res.data.code == "10011") {
      //     this.reLoginModal = true;
      //     sendData.relogin = true;
      //     this.reloginData = sendData;
      //   }
      // });
    },
  },
  destroyed() {
    clearTimeout(this.captchaTimeOut);
  },
};
</script>
<style lang="scss" scoped>
@import "./style.scss";

.FAModal {
  .van-dialog__content {
    display: flex;
    flex-direction: column;
  }
}
.loginFromWrap {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  margin-top: -75px;
  .inputRound {
    margin-bottom: 15px;
    background-color: #f5f7ff;
    width: 75%;
    input {
      font-weight: normal;
    }
    label {
      color: #d2d2d2;
      font-weight: normal;
    }
  }
}

.tyhBG {
  background-image: unset !important;
  background-color: rgb(169, 213, 253);
}
.shBG {
  //background-image: url("/sh.jpg") !important;
  background-color: rgb(169, 213, 253);
  background-size: 100% auto !important;
  background-position: top !important;
  background-attachment: unset !important;
}

.loginWrap {
  position: relative;
  overflow: auto; /* 添加滚动条 */
}
.shImg {
  display: none;
}

.shBG .shImg {
  display: block;
}

.topBc {
  width: 100%;
}

.loginTitle {
  color: #4361ff;
  font-size: 56px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 0;
  font-family: Reeji-CloudZongYi-GB;
  width: 75%;
  .loginTitleIngo {
    font-size: 16px;
    margin-top: 0;
    color: #3dbef6;
    img {
      width: 14px;
    }
  }
}
.validateWrap input {
  width: 100%;
}
.validateImg {
  position: absolute;
  right: 45px;
}
.loginBtnWrap {
  background-color: #3dbef6;
  width: 75%;
  color: white;
  font-weight: 400;
  height: 44px;
  border-radius: 20px;
  text-align: center;
  line-height: 44px;
  font-size: 36px;
  p {
    font-family: Nunito Sans;
    font-size: 20px;
  }
}
.btnI {
  display: flex;
  justify-content: flex-end;
}
</style>
